
import { Component, Prop, Vue } from 'vue-property-decorator';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import QuickFilterFilter from '@/models/Table/Legacy/LegacyQuickFilterFilter';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import TabsComponent from '@/models/Table/Legacy/LegacyTabsComponent.vue';
import {
  TableConfigurations,
  TableFilterTypes
} from '@/models/Table/Legacy/LegacyTable';
import Routes from '@/router/Routes';
import {
  applicantCollectionTableColumnOrderConfig,
  applicantTableConfigurations
} from '@/models/Table/Configurations/ApplicantTableConfiguration';
import { RecruiterApplicant } from '@/models/Recruiter/RecruiterApplicant';
import IconComponent from '@/components/IconComponent.vue';
import CreateApplicantModal from '@/components/Modals/Applicant/CreateApplicantModalComponent.vue';
import BreadCrumbComponent, {
  BreadCrumbLink
} from '@/components/BreadCrumbComponent.vue';
import { Recruiter } from '@/models/Entities/Personas/Recruiter';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { Application } from '@/models/Entities/Application/Application';

class TableViewModel {
  firstName!: string;
  middleName!: string | null;
  lastName!: string;
  email!: string;
  city!: string | null;
  zipCode!: string | null;
  homePhone!: number | null;
  socialSecurityNumber!: string | null;
  workPhone!: number | null;
  id!: number;
  profileIncomplete!: boolean;
  profilePartiallyComplete!: boolean;
  recruiterSignatureRequired!: boolean;
  readyForBoardSubmission!: boolean;
  applicationPartiallyComplete!: boolean;

  constructor(applicant: RecruiterApplicant) {
    this.firstName = applicant.identificationInformationFirstname;
    this.middleName = applicant.identificationInformationMiddlename;
    this.lastName = applicant.identificationInformationLastname;
    this.email = applicant.contactInformationEmail;
    this.city = applicant.contactInformationCity;
    this.zipCode = applicant.contactInformationZipcode;
    this.homePhone = applicant.contactInformationHomephone;
    this.workPhone = applicant.contactInformationWorkphone;
    this.socialSecurityNumber =
      applicant.identificationInformationSocialsecuritynumber;
    this.id = applicant.id;
    this.profileIncomplete = applicant.profileIncomplete;
    this.recruiterSignatureRequired = applicant.recruiterSignatureRequired;
    this.readyForBoardSubmission = applicant.readyForBoardSubmission;
    this.applicationPartiallyComplete = applicant.applicationPartiallyComplete;
  }
}

@Component<FlightChiefRecruiterDetailsPage>({
  components: {
    TabsComponent,
    LegacyTableComponent,
    QuickFilterComponent,
    IconComponent,
    CreateApplicantModal,
    BreadCrumbComponent
  }
})
export default class FlightChiefRecruiterDetailsPage extends Vue {
  @Prop({
    required: true
  })
  recruiter!: Recruiter;

  Routes = Routes;

  tableConfigurations: TableConfigurations = applicantTableConfigurations;
  applicantCollectionTableColumnOrderConfig =
    applicantCollectionTableColumnOrderConfig;
  applicationsByApplicant = new Map();

  $refs!: {
    createApplicantModal: CreateApplicantModal;
  };

  async created(): Promise<void> {
    for (let i = 0; i < this.recruiter.candidates.length; i++) {
      const applicant = this.recruiter.candidates[i];
      const application = await this.getAllApplications(applicant);
      this.applicationsByApplicant.set(applicant, application);
    }
  }

  get links(): BreadCrumbLink[] {
    return [
      {
        label: 'Flight Chief',
        route: Routes.FLIGHT_CHIEF_RECRUITER_COLLECTION
      },
      {
        label:
          this.recruiter.identificationInformationFirstname +
          ' ' +
          this.recruiter.identificationInformationLastname
      }
    ];
  }

  get recruiterApplicants(): RecruiterApplicant[] {
    return this.recruiter.candidates.map((applicant) => {
      const applications = this.applicationsByApplicant.get(applicant);
      return RecruiterApplicant.newFromApplicant(applicant, applications);
    }) as RecruiterApplicant[];
  }

  get tableData(): TableViewModel[] {
    return this.recruiterApplicants.map(
      (applicant) => new TableViewModel(applicant)
    );
  }

  get numberReadyForSubmission(): number {
    return this.recruiterApplicants.filter(
      (applicant: RecruiterApplicant) => applicant.readyForBoardSubmission
    ).length;
  }
  get numberThatNeedRecruiterSignature(): number {
    return this.recruiterApplicants.filter(
      (applicant: RecruiterApplicant) => applicant.recruiterSignatureRequired
    ).length;
  }
  get numberOfPartiallyCompletedApplications(): number {
    return this.recruiterApplicants.filter(
      (applicant: RecruiterApplicant) => applicant.applicationPartiallyComplete
    ).length;
  }
  get numberWithIncompleteProfiles(): number {
    return this.recruiterApplicants.filter(
      (applicant: RecruiterApplicant) => applicant.profileIncomplete
    ).length;
  }

  get quickFilters(): QuickFilterFilter[] {
    return [
      new QuickFilterFilter({
        filter: 'Ready for Board Submission',
        count: this.numberReadyForSubmission,
        column: 'readyForBoardSubmission',
        filterType: TableFilterTypes.BOOLEAN
      }),
      new QuickFilterFilter({
        filter: "Recruiter's Signature Required",
        count: this.numberThatNeedRecruiterSignature,
        column: 'recruiterSignatureRequired',
        filterType: TableFilterTypes.BOOLEAN
      }),
      new QuickFilterFilter({
        filter: 'Partial UFT Application',
        count: this.numberOfPartiallyCompletedApplications,
        column: 'applicationPartiallyComplete',
        filterType: TableFilterTypes.BOOLEAN
      }),
      new QuickFilterFilter({
        filter: 'Incomplete Profile',
        count: this.numberWithIncompleteProfiles,
        column: 'profileIncomplete',
        filterType: TableFilterTypes.BOOLEAN
      })
    ];
  }

  async createApplicant(): Promise<void> {
    const createApplicantModal = this.$refs['createApplicantModal'];
    try {
      await createApplicantModal.open();
      this.$store.dispatch('snackBarModule/enqueue', {
        icon: 'checkGreen',
        message: 'Applicant created'
      });
      await this.$store.dispatch('recruiterModule/fetch', this.recruiter.id);
    } catch (err: any) {
      if (err) {
        this.$store.dispatch('snackBarModule/enqueue', {
          icon: 'caution',
          message: err.message ?? 'There was an error creating the applicant'
        });
        // re-try
        this.createApplicant();
      }
    }
  }

  async cellEventHandler(
    column: string,
    tableViewModel: TableViewModel,
    payload: unknown
  ): Promise<void> {
    if (typeof payload == 'boolean') return;
    if (column === 'lastName') {
      this.$router.push({
        name: Routes.APPLICANT_DETAILS,
        params: { id: String(tableViewModel.id) }
      });
    }
  }

  async getAllApplications(applicant: Applicant): Promise<Application[]> {
    return await this.$store.dispatch('applicantModule/findApplications', {
      archived: true,
      candidateIds: [applicant.id]
    });
  }
}
